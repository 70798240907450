export default [
  {
    name: "login",
    path: "/login/:key?",
    component: () => import(/* webpackChunkName: "login", webpackPreload: true */ "@/pages/auth/Login"),
    meta: {}
  },
  {
    name: "register",
    path: "/register",
    component: () => import(/* webpackChunkName: "login", webpackPreload: true */ "@/pages/auth/Register"),
    meta: {},
    layout: "Register"
  },
  {
    name: "register-success",
    path: "/register/success",
    component: () => import(/* webpackChunkName: "login", webpackPreload: true */ "@/pages/auth/RegisterSuccess"),
    meta: {},
    layout: "Register"
  },
  {
    name: "reset-password",
    path: "/reset-password/:key?",
    component: () => import(/* webpackChunkName: "reset-password", webpackPreload: true */ "@/pages/auth/ResetPassword"),
    meta: {}
  },
  {
    name: "update-password",
    path: "/update-password/:key?",
    component: () => import(/* webpackChunkName: "update-password", webpackPreload: true */ "@/pages/auth/UpdatePassword"),
    meta: {}
  },
];
